import thingshappen  from '../assets/thingshappen.png';
import contact  from '../assets/contact.png';
import logo  from '../assets/69.png';
import logo01  from '../assets/logo01.png';
import logo02  from '../assets/logo02.png';
import logo03  from '../assets/logo03.png';
import logo04  from '../assets/logo04.png';
import logo05  from '../assets/logo05.png';
import logo06  from '../assets/logo06.png';
import logofooter  from '../assets/69.png';
import services01  from '../assets/appdev.png';
import services02  from '../assets/webdev.png';
import services03  from '../assets/cyber.jpg';
import services04  from '../assets/cloud.png';
import services05  from '../assets/ml.png';
import services06  from '../assets/block.png';
import team02 from '../assets/bib.jpg';
import team03 from '../assets/pivme.jpg';
import team04 from '../assets/aadrash.png';
import team05 from '../assets/dvd.png';
import team06 from '../assets/vyh.png';
import team01 from '../assets/har.png';
import hero from '../assets/hero12.png';
import about from '../assets/about.png';
import menu from '../assets/menu.png';
import team07 from '../assets/swara.png';


// eslint-disable-next-line import/no-anonymous-default-export
export default{
menu,
thingshappen,
contact,
logo,
logo01,
logo02,
logo03,
logo04,
logo05,
logo06,
logofooter,
services01,
services02,
services03,
services04,
services05,
services06,
team02,
team03,
team04,
team05,
team06,
team01,
team07,
hero,
about,

};