import React from 'react'
import { Headings } from '../../components';
import './Contact.css';
const Contact = () => {
  return (
    <div classNameName="section-padding">
      <Headings title="Contact Us" text="Connect with Us: Let's  Discuss Your Technical Needs" />

      <div className="contact" id="quote">

        <div className="row">
          
        </div>
      </div>
    </div>
  )
}

export default Contact